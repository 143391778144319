import React from 'react'
import { locale } from '../../../src/common/i18n'

interface Props {
  styles: { [k: string]: string }
}

const CondicionesEs = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Condiciones generales de contratación</h1>
    <p>
      El presente acuerdo regula las condiciones generales de compra de los
      diversos productos y servicios ofertados en la presente página web por EL
      PASO 2.000 TECHNOLOGY, S.L.U., con NIF nº B76177880 y domicilio en
      C/Josefina Mayor, 32. Telde, 35219 Telde, Las Palmas (ESPAÑA).
    </p>
    <p>
      El CLIENTE en el momento que completa el proceso de registro, y compra
      alguno de nuestros productos, acepta y se somete expresamente a las
      cláusulas especificadas a continuación, así como a las condiciones de
      acceso y uso de nuestro portal y su política de prIGICcidad, accesibles a
      través del enlace “Avisos Legales”.
    </p>
    <p>
      El CLIENTE reconoce disponer de capacidad legal suficiente para obligarse
      contractualmente.
    </p>
    <p>
      El presente contrato está permanentemente accesible en los avisos legales
      de nuestra página web, y se puede descargar en el momento de la
      contratación.
    </p>

    <h2 className={styles.section_title}>
      <strong>1. Precios e impuestos</strong>
    </h2>

    <p>
      En contraprestación por el producto adquirido, el CLIENTE acepta pagar
      expresamente a EL PASO 2000 las cantidades especificadas en el momento de
      la compra en la relación de precios establecidas en la web{' '}
      <u>
        <a href="https://www.elpaso2000.com/">www.elpaso2000.com</a>
      </u>{' '}
      para los productos elegidos.
    </p>
    <p>
      Los productos que se ofrecen en esta web están sujetos al Impuesto del
      Valor Añadido (IGIC) que ESTÁ INCLUIDO en los precios que se detallan para
      cada producto.
    </p>
    <p>
      El coste de montaje o instalación de los productos NO ESTÁ INCLUIDO en el
      precio.
    </p>
    <p>
      Una vez finalizada la selección de productos y de manera previa a la
      confirmación del pedido y antes de proceder al pago del mismo, en pantalla
      aparecerá el precio final de la compra que incluirá el IGIC , los gastos
      de instalación y de montaje, que se reflejarán en la orden de pedido en el
      momento de efectuar la compra y en la factura.
    </p>
    <p>
      No se incluye todo aquello que no aparezca especificado en la descripción
      como incluido.
    </p>

    <h2 className={styles.section_title}>
      <strong>2. Procedimiento de compra</strong>
    </h2>
    <p>
      A través de esta página web solamente podrán ser realizados pedidos por
      personas físicas mayores de 14 años y sociedades.
    </p>
    <p>
      En la página web se detallan todos nuestros productos de manera
      individualizada. El procedimiento para efectuar y gestionar la compra se
      va indicando en la página web y sólo deben seguirse los siguientes pasos:
    </p>
    <ol className={styles.listNumber}>
      <li>
        <p>Elegir el producto que desee adquirir.</p>
      </li>
      <li>
        <p>
          Al seleccionar un producto podrá ser añadido a la Cesta de la Compra y
          podrá elegir entre seguir comprando o tramitar el pedido.
        </p>
      </li>
      <li>
        <p>
          Para tramitar el pedido, si es la primera vez que accede a nuestro
          portal, es preciso cumplimentar un formulario de registro con sus
          datos personales y crear una cuenta personal como usuario. Completado
          este paso recibirá, en el correo electrónico que haya indicado, la
          confirmación de que el registro se ha realizado correctamente. En caso
          de que sea un usuario ya registrado, para continuar con el proceso de
          compra bastará que introduzca el nombre de usuario y contraseña que
          generó en la primera compra. El código de usuario y la contraseña son
          personales y corresponden a la persona que efectuó el proceso de
          registro, quien es responsable exclusivo su custodia. A través de su
          cuenta podrá gestionar la información que disponemos y modificar o
          actualizar sus datos.
        </p>
      </li>
      <li>
        <p>
          A continuación, se procederá a la verificación del pedido donde se
          detallan uno a uno los artículos seleccionados y se calcula el precio
          total, incluyendo gastos de taller e impuestos.
        </p>
      </li>
      <li>
        <p>
          Asimismo, será necesario seleccionar el taller donde desea que se le
          instale el producto y seleccionar una fecha a modo de cita para el
          montaje.
        </p>
      </li>
      <li>
        <p>
          Una vez tramitado el proceso, será necesario marcar de manera previa
          una casilla de verificación de lectura y aceptación los términos y
          condiciones de venta.
        </p>
      </li>
      <li>
        <p>
          En la página web se le facilitarán los mecanismos para que pueda
          efectuarse el pago en función del sistema elegido en cada caso.
        </p>
      </li>
      <li>
        <p>
          Una vez introducidos los datos para el pago, deberá confirmar el
          pedido pulsando el botón "Comprar".
        </p>
      </li>
      <li>
        <p>
          Una vez pulsado el botón "Comprar" recibirá instrucciones para la
          entrega del producto.
        </p>
      </li>
    </ol>
    <p>
      El CLIENTE se da por informado de que las fotografías y descripciones de
      los productos en la página web son una imagen aproximada de los productos
      y servicios ofertados por lo que es posible que puedan diferir del
      original. Las descripciones e ilustraciones de los productos ofrecidos a
      través de la presente página web se realizan meramente a título
      informativo.
    </p>

    <h2 className={styles.section_title}>
      <strong>3. Disponibilidad y Entrega</strong>
    </h2>

    <p>
      Los productos ofertados por EL PASO 2000 se encuentran disponibles para su
      compra en toda la Unión Europea pero la entrega y el montaje se realizará
      exclusIGICmente en uno de los talleres disponibles en la web.
    </p>
    <p>
      Los pedidos se procesarán una vez que se haya recibido correctamente el
      pago.
    </p>
    <p>
      Los productos ofertados por EL PASO 2000 estarán siempre sujetos a
      disponibilidad que se indicará específicamente en nuestra Web para cada
      producto por lo que, en caso de no encontrarse disponible algún artículo
      ofertado, se informará debidamente de la falta de disponibilidad al
      cliente para la compra. En condiciones normales, todos los productos que
      aparecen en nuestra Web se encuentran disponibles para su montaje.
    </p>
    <p>
      En los casos en los que, una vez realizado el pedido, el producto
      contratado por el CLIENTE se encuentre agotado, se le informará de ello
      debidamente y se le ofrecerá un producto alternativo de características
      similares de igual precio y calidad al comprado. Si, aun así, el CLIENTE
      no estuviera interesado en aceptar la alternatIGIC propuesta, EL PASO 2000
      procederá a la devolución de la suma ya abonada y le informará de los
      trámites y plazos de reembolso.
    </p>
    <p>
      El CLIENTE deberá seleccionar una de las fechas disponibles en el taller
      de su elección que, una vez marcada, será la fecha de entrega del
      producto.
    </p>
    <p>
      Las entregas e instalaciones se realizarán en horario de apertura
      comercial de nuestros talleres.
    </p>
    <p>
      Con el objeto de que en todo momento esté informado de la gestión de su
      compra, recibirá por correo electrónico un aviso de la confirmación de su
      pedido y del pago.
    </p>
    <p>
      Asimismo, el CLIENTE puede consultar el estado de su pedido llamando en
      horario comercial de apertura al teléfono 922 10 94 41 si reside en
      Tenerife, al teléfono 928 58 53 33 si reside en Gran Canaria o enviando un
      correo electrónico a la dirección{' '}
      <u>
        <a href="mailto:atencionalcliente@elpaso2000.com">
          atencionalcliente@elpaso2000.com
        </a>
      </u>
      .
    </p>
    <h2 className={styles.section_title}>
      <strong>4. Pago y periodos de pago</strong>
    </h2>

    <p>Se acepta las siguientes formas de pago:</p>
    <ul>
      <li>
        <p>
          Tarjeta de Crédito: La transacción se realiza conectando directamente
          con la entidad bancaria a través de su pasarela de pago o,
          directamente, en el taller.
        </p>
      </li>
    </ul>
    <p>
      En los pagos a través de la web, una vez realizado el pago, recibirá un
      correo electrónico de confirmación del mismo.
    </p>
    <p>
      EL PASO 2000 no tiene conocimiento de los datos referentes a su tarjeta de
      crédito. Todos nuestros sistemas de pago son totalmente seguros.
    </p>
    <h2 className={styles.section_title}>
      <strong>5. Facturas</strong>
    </h2>
    <p>
      Una vez efectuado y completado el servicio en el taller, recibirá la
      Factura de la compra. Esta será en formato PDF en su espacio personal
      dentro de la web o en papel si la solicita en el taller.
      <br />
      <br /> El CLIENTE es responsable de la confirmación de la recepción de las
      notificaciones y de poner en nuestro conocimiento cualquier modificación
      sobre sus datos, quedando EL PASO 2000 exonerada de cualquier
      responsabilidad derIGICda por esta circunstancia.
    </p>

    <h2 className={styles.section_title}>
      <strong>6. Garantía</strong>
    </h2>

    <p>
      Todos nuestros productos quedan sometidos a controles de calidad y están
      garantizados contra defectos de producción por un plazo de 2 años desde la
      fecha de compra. La garantía cubre cualquier vicio de fabricación, de
      diseño o de material, aunque será necesario que se ponga de manifiesto la
      falta de conformidad en un plazo de 14 días desde que tuvo conocimiento de
      ella. La garantía cubre únicamente los productos defectuosos. No cubre un
      uso inadecuado, u otros que no sean atribuibles a un defecto del proveedor
      o a una tara en el producto. Durante el periodo de garantía el CLIENTE
      podrá acudir al taller donde le instalaron el producto y procederemos a su
      reparación o sustitución.
    </p>
    <p>El documento de garantía es la factura de compra.</p>

    <h2 className={styles.section_title}>
      <strong>7. Devoluciones</strong>
    </h2>

    <p>
      Sólo se admitirán devoluciones o cambios de los productos en caso de
      defecto de fábrica o errores de montaje. En cualquier caso, no deberán
      haber transcurrido más de 14 días desde la recepción del producto. Se
      comprobará previamente a la aceptación de que se trata de un defecto o
      anomalía de fabricación o de embalaje y no un uso, inadecuado o
      negligente. Se acompañarán siempre de la correspondiente la factura.
    </p>
    <p>
      Tanto en el caso de devolución por defecto de fabricación como en el caso
      de error en el pedido se ofrecerá a CLIENTE, si es posible, la reparación
      de la pieza o su sustitución por otra alternatIGIC sin coste adicional y
      sin derecho, por parte del CLIENTE, a ningún tipo de compensación.
    </p>
    <p>
      En todo caso, la devolución quedará siempre sujeta a previo examen por
      parte de EL PASO 2000 del estado del producto objeto de devolución y a la
      verificación de que se han cumplido los requisitos exigibles en las
      presentes condiciones de venta. Una vez recibida la devolución y en caso
      de que proceda, reembolsaremos el importe cobrado en un plazo máximo de 30
      días y en los mismos términos que se usaron para realizar el pago.
    </p>
    <p>
      EL PASO 2000 se reserva el derecho de rechazar devoluciones comunicadas
      fuera del plazo fijado o de productos que no reúnan los criterios para su
      devolución.
    </p>
    <p>
      Las devoluciones se gestionarán a través del envío de un correo
      electrónico a{' '}
      <u>
        <a href="mailto:atencionalcliente@elpaso2000.com">
          atencionalcliente@elpaso2000.com
        </a>
      </u>{' '}
      o acudiendo al taller realizando una descripción de los motivos y de las
      causas alegadas para la devolución. Asimismo, será necesario señalar el
      nombre y apellidos y el número de referencia del pedido. Una vez recibida
      la petición, la tramitaremos oportunamente.
    </p>

    <h2 className={styles.section_title}>
      <strong>8. Derecho de Desistimiento</strong>
    </h2>

    <p>
      Tiene usted derecho a desistir del presente contrato en un plazo de 14
      días naturales sin necesidad de justificación.
    </p>
    <p>
      El plazo de desistimiento expirará, en cualquier caso, en el momento de la
      instalación del producto en el taller.
    </p>
    <p>
      Para ejercer el derecho de desistimiento, deberá usted notificarnos a EL
      PASO 2.000 TECHNOLOGY, S.L.U. C/Josefina Mayor, 32. 35219 Telde, Las
      Palmas, correo electrónico:{' '}
      <u>
        <a href="mailto:atencionalcliente@elpaso2000.com">
          atencionalcliente@elpaso2000.com
        </a>
      </u>
      , su decisión de desistir del contrato a través de una declaración
      inequívoca (por ejemplo, una carta enviada por correo postal, fax o correo
      electrónico). Podrá utilizar el modelo de formulario de desistimiento que
      figura a continuación, aunque su uso no es obligatorio.
    </p>
    <p>Modelo de formulario de desistimiento</p>
    <p>A la atención de</p>
    <p>EL PASO 2.000 TECHNOLOGY, S.L.U.</p>
    <p>C/Josefina Mayor, 32.</p>
    <p>35219 Telde, Las Palmas</p>
    <p>
      <u>
        <a href="mailto:atencionalcliente@elpaso2000.com">
          atencionalcliente@elpaso2000.com
        </a>
      </u>
    </p>
    <p>
      Por la presente le comunico que desisto de mi contrato de venta del
      siguiente bien:
    </p>
    <p>Recibido/Pedido el:</p>
    <p>Nombre del consumidor:</p>
    <p>Firma del consumidor</p>
    <p>Fecha</p>
    <p> </p>
    <p>
      Para cumplir el plazo de desistimiento, basta con que la comunicación
      relatIGIC al ejercicio por su parte de este derecho sea enviada antes de
      que venza el plazo correspondiente.
    </p>
    <p>
      <strong>Consecuencias del desistimiento:</strong>
    </p>
    <p>
      En caso de desistimiento por su parte, le devolveremos todos los pagos
      recibidos de usted, sin ninguna demora indebida y, en todo caso, a más
      tardar 14 días naturales a partir de la fecha en la que se nos informe de
      su decisión de desistir del presente contrato. Procederemos a efectuar
      dicho reembolso utilizando el mismo medio de pago empleado por usted para
      la transacción inicial, a no ser que haya usted dispuesto expresamente lo
      contrario; en todo caso, no incurrirá en ningún gasto como consecuencia
      del reembolso.
    </p>

    <h2 className={styles.section_title}>
      <strong>
        9. Perfección del contrato y modificaciones de los servicios
      </strong>
    </h2>

    <p>
      El presente contrato se perfeccionará con la instalación y pago del
      producto.
    </p>
    <p>
      EL PASO 2000 se reserva el derecho a modificar de cualquier forma las
      características y condiciones de sus servicios, siempre con el objetivo de
      mejorarlos y que suponga un beneficio para el CLIENTE.
    </p>

    <h2 className={styles.section_title}>
      <strong>10. Obligaciones de las partes</strong>
    </h2>
    <p>
      EL PASO 2000 se compromete a proporcionar al CLIENTE los productos y
      servicios que hayan sido contratados aplicando la máxima diligencia en la
      prestación de los mismos, así como a cuidar del mantenimiento de las
      instalaciones necesarias para el funcionamiento de la red, disponiendo
      para ello de un equipo técnico e informático adecuado, y a gestionar las
      operaciones de montaje de la compra realizada a través de sus talleres.
    </p>
    <p>
      El CLIENTE se compromete a utilizar los servicios que se ponen a su
      disposición de buena fe, sin vulnerar las leyes vigentes en cualquier
      materia o infringir derechos de terceras personas, así como al pago del
      producto seleccionado en el tiempo y forma fijado en las presentes
      condiciones de venta y a su recepción en el lugar indicado para la
      entrega.
    </p>
    <p>
      EL PASO 2000 no garantiza que la disponibilidad del servicio objeto de
      este contrato sea continua e ininterrumpida, por circunstancias originadas
      por problemas en la red de Internet, averías en los dispositivos
      informáticos y otras circunstancias imprevisibles. De manera que el
      CLIENTE acepta soportar dentro de unos límites razonables estas
      circunstancias, por lo que renuncia expresamente a reclamar a EL PASO 2000
      cualquier responsabilidad contractual o extracontractual por los posibles
      fallos, errores y uso del servicio contratado.
    </p>
    <h2 className={styles.section_title}>
      <strong>11. Comunicaciones</strong>
    </h2>

    <p>
      Todas las notificaciones entre las partes se realizarán preferentemente a
      través de correo electrónico. El CLIENTE es responsable de la confirmación
      de la recepción de las notificaciones y de poner en conocimiento de EL
      PASO 2000 cualquier modificación sobre sus datos, quedando ésta exonerada
      de cualquier responsabilidad derIGICda por esta circunstancia. En caso de
      modificación el CLIENTE deberá ponerlo en conocimiento de EL PASO 2000 en
      el e-mail o teléfonos indicados en nuestra página web.
    </p>

    <h2 className={styles.section_title}>
      <strong>12. Compromiso, aceptación y validez del contrato</strong>
    </h2>
    <p>
      El CLIENTE reconoce haber leído y aceptado las condiciones legales de uso
      y la política de prIGICcidad de la página web.
    </p>
    <p>
      El CLIENTE reconoce que ha entendido toda la información respecto a los
      productos y servicios, incluyendo, en su caso, el de entrega, ofrecidos en
      nuestra página web, así como todas las condiciones y estipulaciones
      recogidas en el presente contrato electrónico, por lo que afirma que son
      suficientes para la exclusión del error en el consentimiento del presente
      contrato y, por lo tanto, las acepta integra y expresamente.
    </p>
    <p>
      El CLIENTE es plenamente consciente de que la aceptación y ejecución del
      presente contrato tendrá lugar por medio del suministro de sus datos y la
      pulsación del botón “Comprar” indicado en nuestra página web y que se
      perfeccionará con el pago del servicio.
    </p>

    <h2 className={styles.section_title}>
      <strong>13. NormatIGIC Aplicable</strong>
    </h2>

    <p>
      El presente contrato tiene carácter mercantil, y se regirá e interpretará
      de acuerdo con las leyes españolas.
    </p>
    <p>
      En el caso de que se produzca cualquier tipo de discrepancia o diferencia
      entre las partes en relación con la interpretación, contenido o ejecución
      del Contrato que no sea solucionada de mutuo acuerdo, las Partes se
      someterán a los juzgados o tribunales que resultaran competentes en cada
      caso.
    </p>

    <h2 className={styles.section_title}>
      <strong>14. Datos personales </strong>
    </h2>
    <p>
      El proceso de contratación requiere que cumplimente formularios de datos
      personales con la finalidad de tramitar y gestionar los pedidos y, si lo
      autoriza, para remitirle información comercial sobre nuestros productos y
      servicios. El suministro de dichos datos personales requiere la aprobación
      previa de nuestra Política de PrIGICcidad.
    </p>
    <p> </p>
    <p>
      <strong>Atención:</strong> Las presentes Condiciones Generales de Compra
      han sido actualizadas con fecha 30/7/2019. En cualquier momento podemos
      proceder a su modificación, así como variar la relación de los precios y
      productos ofertados. Por favor, compruebe la fecha de emisión en cada
      ocasión en que se conecte a nuestra página Web y así tendrá la certeza de
      que no se ha producido modificación alguna que le afecte.
    </p>
  </div>
)

const CondicionesContents = ({ ...props }: Props) => (
  <CondicionesEs {...props} />
)

export default CondicionesContents
